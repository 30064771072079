import {asFormData, asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    fetchCompanies: (data) => asJson(axios).get(getVerUri('company/network'), data),
    fetchCompaniesList: (data) => asJson(axios).get(getVerUri('company/network'), data),
    searchCompany: data => asJson(axios).get(getVerUri('company/network/simple-search'), {params: {search: data}}),
    getUsersNotAddToProject: (data) => asJson(axios).get(getVerUri('team/show-not-added-users'), data),
    getCompanyAllUsers: (data) => asJson(axios).get(getVerUri('company/get-all-users'), data),
    getCompanyUsers: (data) => asJson(axios).get(getVerUri('company/get-users'), data),
    setWorkflow: (data) => asJson(axios).post(getVerUri('project/set-workflow'), data),
    removeCompany: (data) => asJson(axios).post(getVerUri('company/network/remove-company-by-id'), data),
    fetchNewCompany: (data) => asJson(axios).get(getVerUri('company/network/search-not-added-company'), data),
    findCompanyEmail: (data) => asJson(axios).get(getVerUri('company/network/find-by-email'), data),
    addCompanyById: (data) => asJson(axios).post(getVerUri('company/network/add-company-by-id'), data),
    getCompanyInfo: (data) => asJson(axios).get(getVerUri('settings/company/general-info'), data),
    getCompanyTypes: () => asJson(axios).get(getVerUri('settings/company/company-type/general')),
    getCompanyRoles: () => asJson(axios).get(getVerUri('settings/company/roles')),
    getNotes: (data) => asJson(axios).get(getVerUri('company/notes'), data),
    createNote: (data) => asJson(axios).post(getVerUri('company/notes/create'), data),
    updateNote: (id, data) => asJson(axios).put(getVerUri('company/notes/update/' + id), data),
    deleteNote: (id) => asJson(axios).delete(getVerUri('company/notes/delete/' + id)),
    importCompany: (data) => asFormData(axios).post(getVerUri('company/import'), data),
    exportCompany: () => getVerUri('company/network/export'),
    companyDocuments: (data) => asJson(axios).get(getVerUri('company/documents'), data),
    tagsDocuments: (data) => asJson(axios).get(getVerUri('company/documents/used-tags'), data),
    addToQuickAccess: (data) => asJson(axios).post(getVerUri('company/documents/add-to-quick-access'), data),
    removeToQuickAccess: (data) => asJson(axios).post(getVerUri('company/documents/remove-from-quick-access'), data),
})
