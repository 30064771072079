import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    fetchProjects: (data) => asJson(axios).get(getVerUri('projects'), data),
    createProject: (data) => asJson(axios).post(getVerUri('projects'), data),
    updateProject: (id, data) => asJson(axios).put(getVerUri('projects/' + id), data),
    getCompanyProjects: id => asJson(axios).get(getVerUri('projects/with-network-company/' + id)),
    getStageList: (data) => asJson(axios).get(getVerUri('project/stage'), data),
    getOwnerTypeList: (data) => asJson(axios).get(getVerUri('project/owner-type'), data),
    getProjectInfo: (projectId) => asJson(axios).get(getVerUri('projects/' + projectId)),
    deleteProject: (projectId) => asJson(axios).delete(getVerUri('projects/' + projectId)),
    fetchProjectsList: (data) => asJson(axios).get(getVerUri('project/names-list'), data),
    fetchActivity: (data) => asJson(axios).get(getVerUri('project/activity'), data),
    exportActivity: () => getVerUri('project/activity/download'),
    fetchContracts: (data) => asJson(axios).get(getVerUri('contracts'), data),
    fetchTeam: (data) => asJson(axios).get(getVerUri('team/show-users'), data),
    teamAdd: (data) => asJson(axios).post(getVerUri('team/add-user'), data),
    teamRemove: (data) => asJson(axios).post(getVerUri('team/remove-user'), data),
    getScopeApprovalRounds: (data) => asJson(axios).get(getVerUri('scope/approval-rounds'), data),
    deleteAttachment: (data) => asJson(axios).post(getVerUri('scope/attachment/delete'), data),
    getCompanyTypes: (data) => asJson(axios).get(getVerUri('settings/company/company-type/enabled'), data),
    getProjectCollectionList: (data) => asJson(axios).get(getVerUri('project/temporary-collection'), data),
    saveProjectCollectionList: (data) => asJson(axios).post(getVerUri('project/temporary-collection'), data),
    getProjectFields: (data) => asJson(axios).get(getVerUri('project/creation-form/section/all'), data),
    isUsingOption: (data) => asJson(axios).get(getVerUri('project/creation-form/custom-field/is-using-option'), data),
    createProjectField: (data) => asJson(axios).post(getVerUri('project/creation-form/custom-field/create'), data),
    editProjectField: (data) => asJson(axios).post(getVerUri('project/creation-form/custom-field/edit'), data),
    editOwnerType: (data) => asJson(axios).post(getVerUri('project/owner-type/create'), data),
    editStage: (data) => asJson(axios).post(getVerUri('project/stage/create'), data),
    deleteProjectField: (data) => asJson(axios).post(getVerUri('project/creation-form/custom-field/delete'), data),
    projectDocuments: (data) => asJson(axios).get(getVerUri('project/documents'), data),
    tagsDocuments: (data) => asJson(axios).get(getVerUri('project/documents/used-tags'), data),
    addToQuickAccess: (data) => asJson(axios).post(getVerUri('company/documents/add-to-quick-access'), data),
    removeToQuickAccess: (data) => asJson(axios).post(getVerUri('company/documents/remove-from-quick-access'), data),
    saveSecondStep: (projectId, data) => asJson(axios).post(getVerUri('project/step-2?project_id=' + projectId), data),
    getScopeWorkflowFields: (data) => asJson(axios).get(getVerUri('project/step-3/fields'), data),
    getUsersForWorkflowFields: (data) => asJson(axios).get(getVerUri('project/users-for-workflow-field'), data),
    saveThirdStep: (projectId, data) => asJson(axios).post(getVerUri('project/step-3?project_id=' + projectId), data),
    getContractWorkflowFields: (data) => asJson(axios).get(getVerUri('project/step-4/fields'), data),
    saveFourthStep: (projectId, data) => asJson(axios).post(getVerUri('project/step-4?project_id=' + projectId), data),
    removeTemplateBuilder: (data) => asJson(axios).post(getVerUri('project/temporary-collection/template-mark-as-deleted'), data),
    saveFifthStep: (projectId, projectName) => asJson(axios).post(getVerUri('project/step-5?project_id=' + projectId), projectName),
    getAdditionalDocuments: (projectId) => asJson(axios).get(getVerUri('project/additional-documents?project_id=' + projectId)),
    removeAdditionalDocument: (documentId) => asJson(axios).delete(getVerUri('project/additional-documents/' + documentId)),
    downloadAdditionalDocument: (documentId, type) => asJson(axios).get(getVerUri('project/additional-documents/' + documentId + '/download'), type),
    sendContractData: () => asJson(axios).get(getVerUri('projects/with-contract-sent-scopes')),
})
